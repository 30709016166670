import type { EventCard } from "~/types/events";

export const useEventsStore = defineStore("events", () => {
  const data = ref<EventCard[]>([]);

  async function ensureEvents(force = false) {
    if (force || data.value.length === 0) {
      try {
        const { data: results } = await useAsyncGql(
          "GetCatalogue",
          {
            language: "no-nb",
            path: "/arrangementer",
            version: useCrystallizeVersion(),
          },
          {
            transform: (data) => {
              return (
                data.catalogue?.children
                  ?.map(toEvent)
                  ?.map(toEventCard)
                  .sort(byFirstDate("desc")) ?? []
              );
            },
          },
        );

        data.value = results.value ?? [];
      } catch (error) {
        handleError(error, "Error fetching events");
      }
    }

    return data;
  }

  return {
    data,
    ensureEvents,
  };
});
